
import { instance } from '@/utils/http.js'

// 获取答案解析
export const getQuestionAnswer = function (params) {
  return instance({
    url: '/api/v1/my/analysis',
    method: 'get',
    params
  })
}
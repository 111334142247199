<template>
  <div class="analysis_warp">
    <div class="top">
      <TopCard ref="TopCardRef"
               text="答案解析"
               :showBack="true" />
    </div>

    <div class="fixed_btn">
      <!-- 导出试卷按钮 -->
      <div>
        <ExportPaper :id='user_paper_id' />
      </div>

      <div @click="change(-1)"><img src="@/assets/self_correct/icon-上一页@2x.png"
             alt=""></div>
      <div @click="change(1)"><img src="@/assets/self_correct/icon-下一页@2x.png"
             alt=""></div>
      <div @click="toCard">
        <img src="@/assets/self_correct/icon-答题卡@2x.png"
             alt="">
      </div>
    </div>
    <div class="content">
      <div class="left">
        <div class="topic_content">
          <p class="title">题目{{questionContent['paper_question_no']}}
          </p>
          <div class="topic"
               v-html="questionContent.question_head_content"></div>
          <div class="topic"
               v-html="questionContent.question_content"></div>
        </div>
      </div>
      <div class="right">
        <TopicInfo ref="TopicInfoRef"
                   :question="list[index]"
                   :opertion_heigth="0" />
      </div>
    </div>
  </div>
</template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import TopicInfo from '../correct/components/opertion.vue'
import { getQuestionAnswer } from '@/api/anwser.js'
import ExportPaper from '@/views/components/exportPaper/index.vue'//导出试卷按钮
export default {
  data () {
    return {
      list: [],
      index: 0,
      info: {},

      user_paper_id: ''
    }
  },
  components: {
    TopCard, TopicInfo, ExportPaper
  },
  computed: {
    questionContent () {
      this.list[this.index] = this.list[this.index] ? this.list[this.index] : {
        paper_question_no: '',
        question_code_head: '',
        question_content: '',
      }
      return this.list[this.index]
    }
  },
  created () {
    this.user_paper_id = this.$route.query.paper_id
  },
  mounted () {

    if (window.sessionStorage.getItem('analysis_paper')) {
      this.info = JSON.parse(window.sessionStorage.getItem('analysis_paper'))
      this.index = Number(window.sessionStorage.getItem('analysis_index'))
      this.list = this.info.volist
    } else {
      this.getList()
    }
  },
  methods: {
    async getList () {
      let params = {
        user_paper_id: this.$route.query.paper_id
      }
      const { data } = await getQuestionAnswer(params)
      this.list = data.volist
      this.info = data
    },
    change (num) {
      if (this.index == 0 && num < 0) {
        this.$message.warning('已经是第一题')
        return
      }
      if (this.index == this.list.length - 1 && num > 0) {
        this.$message.warning('已经是最后一题')
        return
      }
      this.index += num
    },
    toCard () {
      window.sessionStorage.setItem('analysis_index', this.index)
      window.sessionStorage.setItem('analysis_paper', JSON.stringify(this.info))
      this.$router.push('/over_card?type=analysis')
    }
  }
}
</script>

<style lang="scss" scoped>
.analysis_warp {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #f6f6f6;
  .top {
    width: 100vw;
    height: 100rem;
  }
  .content {
    display: flex;
    height: calc(100vh - 100rem);
    .left {
      flex: 1;
      background: #ffffff;
      margin-left: 50rem;
      height: 100%;
      overflow: auto;
      .topic_content {
        padding: 30rem;
        overflow: auto;
        .title {
          font-size: 20rem;
          font-weight: 500;
          color: #666666;
        }
        .head {
          font-size: 20rem;
          font-weight: 500;
          color: #000000;
          margin-top: 20rem;
        }
        div {
          margin-top: 20rem;
        }
      }
    }
    .right {
      flex: 1;
      background: #ffffff;
      margin-left: 60rem;
    }
  }
}
.fixed_btn {
  position: fixed;
  top: 50rem;
  right: 30rem;
  font-size: 24rem;
  display: flex;
  // width: 350rem;
  div {
    // flex: 1;
    text-align: center;
  }
  img {
    width: 36rem;
    height: 36rem;
    margin-left: 30rem;
  }
}
.topic {
  font-size: 20rem;
}
</style>
<template>
  <div class="btn">
    <span @click='printWord(0)'>导出试卷 </span>
    <span @click='printWord(1)'
          v-if="this.show_answer == 1">导出试卷(附解析) </span>
    <!-- <span @click='exportImg'>导出试卷为图片</span> -->
    <!-- <span @click='printCard'>导出答题卡</span> -->
  </div>
</template>

<script>
import { exportWord, exportSheet } from '@/api/export.js'
import html2canvas from 'html2canvas'
import { instance } from '@/utils/twoHttp.js'
import { uploadFile } from '@/api/upload.js'

export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {

      show_answer: '',

    }
  },
  mounted () {
    this.show_answer = JSON.parse(window.localStorage.getItem('userInfo')).show_answer

  },
  methods: {
    // 打印试卷
    async printWord (detail) {
      const data = await exportWord({
        user_paper_id: this.id,
        detail
      })
      let i = ''
      if (detail == 0) {
        i = "无解析"
      } else {
        i = '附解析'
      }
      const a = document.createElement("a")
      a.href = window.URL.createObjectURL(data.data)
      a.download = `${this.id}_${i}.pdf`
      a.click()
    },
    async exportImg () {
      window.pageYoffset = 0;//网页位置
      document.documentElement.scrollTop = 0;//滚动条的位置
      document.body.scrollTop = 0;//网页被卷去的高
      let imgs = document.getElementsByTagName('img')
      let strs = ''

      let imagesList = []

      for (var index = 0; index < imgs.length; index++) {
        let str = imgs[index].getAttribute("src")
        if (str.includes('data:image')) continue
        imagesList.push(imgs[index])
        strs += str
        if (index != imgs.length - 1) {
          strs += ',a,'
        }
      }
      strs = strs.trim(',a,')

      const { data } = await instance({
        url: '/imgurl2base64',
        method: 'post',
        data: {
          imgurls: strs
        }
      })
      for (var index = 0; index < imagesList.length; index++) {
        imagesList[index].setAttribute("src", data[index])
        imagesList[index].removeAttribute("width")
        imagesList[index].removeAttribute("height")
      }

      setTimeout(async () => {
        let canvas = await html2canvas(document.querySelector(".topic_list"), {
          // allowTaint: true,  //开启跨域
          // useCORS: true,
        });
        let base64Img = canvas.toDataURL();//将canvas转为base64
        let formdata = new FormData();
        formdata.append("file", this.toImgStyle(base64Img, Date.now() + '.png'));//此处参数一字段为后端要求，参数二后端要求传递形式为png，所以此处又调用toImgStyle方法将base64转为png格式
        formdata.append("filename", "file")
        let form = {
          filename: "file"
        }
        formdata.append('sign', this.$getMd5Form(form))
        const { data } = await uploadFile(formdata)
        // console.log('data', data)
        window.open(data.info.thumb_path, '_blank')

      }, 500)
    },
    toImgStyle (base64Str, fileName) {
      var arr = base64Str.split(','),
        mime = arr[0].match(/:(.*?);/)[1], //base64解析出来的图片类型
        bstr = atob(arr[1]), //对base64串进行操作，去掉url头，并转换为byte   atob为window内置方法
        len = bstr.length,
        u8arr = new Uint8Array(len); //
      while (len--) {
        u8arr[len] = bstr.charCodeAt(len)
      };
      // 创建新的 File 对象实例[utf-8内容，文件名称或者路径，[可选参数，type：文件中的内容mime类型]]
      return new File([u8arr], fileName, {
        type: mime
      })
    },

    async printCard () {
      const { data } = await exportSheet({
        user_paper_id: this.id,
      })
      let url = data.PDFUrl + '?response-content-type=application/octet-stream'
      window.open(url)
    }
  }

}
</script>

<style lang='scss' scoped>
.btn {
  background: #f6f6f6;
  margin-bottom: 20rem;
  span {
    font-size: 20rem;
    margin-left: 30rem;
    padding: 10rem 20rem;
    border: 1px solid #2196f3;
    border-radius: 25rem;
    color: #2196f3;
    cursor: pointer;
  }
}
</style>
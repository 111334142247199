import axios from 'axios'
import { Loading } from 'element-ui'

export const instance = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? 'http://192.168.0.28:9002' : '119.23.111.112:9001',
  baseURL: 'http://119.23.111.112:9001',
  timeout: 360000000,
});

var loadings
function addInterceptors (face) {
  face.interceptors.request.use(config => {

    loadings = Loading.service({
      fullscreen: true,
      target: document.querySelector('#app'),
      text: '正在加载中!',
      spinner: 'el-icon-loading'
    });
    return config
  })

  face.interceptors.response.use(async config => {
    loadings.close()

    config = config.data
    return await new Promise(async (resolve, reject) => {
      if (config.status == 200) {
        resolve(config)
      } else {
        Message.error(config.data.msg)
      }
      resolve(config)
    })
  })
}

addInterceptors(instance)

